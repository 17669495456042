@tailwind base;
@tailwind components;
@tailwind utilities;

@media print {
  /* Measurements for dots */
  .gap-12 { gap: 3cm; }
  .gap-4 { gap: 1cm; }
  .w-2 { width: 0.2cm; }
  .h-2 { height: 0.2cm; }
  
  /* Hide non-printable elements */
  .print\:hidden {
    display: none !important;
  }
  
  /* Reset all backgrounds to white for printing */
  * {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    color-adjust: exact !important;
    background-color: white !important;
  }

  /* Override with specific colors for resistor bands */
  .bg-black { 
    background-color: black !important;
    print-color-adjust: exact !important;
  }
  .bg-amber-900 { 
    background-color: #78350f !important;
    print-color-adjust: exact !important;
  }
  .bg-red-600 { 
    background-color: #dc2626 !important;
    print-color-adjust: exact !important;
  }
  .bg-orange-500 { 
    background-color: #f97316 !important;
    print-color-adjust: exact !important;
  }
  .bg-yellow-400 { 
    background-color: #facc15 !important;
    print-color-adjust: exact !important;
  }
  .bg-green-600 { 
    background-color: #16a34a !important;
    print-color-adjust: exact !important;
  }
  .bg-blue-600 { 
    background-color: #2563eb !important;
    print-color-adjust: exact !important;
  }
  .bg-purple-600 { 
    background-color: #9333ea !important;
    print-color-adjust: exact !important;
  }
  .bg-gray-500 { 
    background-color: #6b7280 !important;
    print-color-adjust: exact !important;
  }
  .bg-white { 
    background-color: white !important;
    print-color-adjust: exact !important;
  }
  .bg-yellow-600 { 
    background-color: #ca8a04 !important;
    print-color-adjust: exact !important;
  }
  
  /* Ensure dots print in black */
  .rounded-full { 
    background-color: black !important;
    print-color-adjust: exact !important;
  }
  
  /* Reset dark mode for printing */
  .dark {
    background-color: white !important;
  }
  
  .dark * {
    background-color: white !important;
    border-color: #e5e7eb !important;
    color: black !important;
  }
  
  /* Exception for specific elements that need color */
  .dark .bg-black { background-color: black !important; }
  .dark .bg-amber-900 { background-color: #78350f !important; }
  .dark .bg-red-600 { background-color: #dc2626 !important; }
  .dark .bg-orange-500 { background-color: #f97316 !important; }
  .dark .bg-yellow-400 { background-color: #facc15 !important; }
  .dark .bg-green-600 { background-color: #16a34a !important; }
  .dark .bg-blue-600 { background-color: #2563eb !important; }
  .dark .bg-purple-600 { background-color: #9333ea !important; }
  .dark .bg-gray-500 { background-color: #6b7280 !important; }
  .dark .bg-yellow-600 { background-color: #ca8a04 !important; }
  
  /* Resistor body color */
  .bg-\[\#E6CCB3\], .dark .bg-\[\#E6CCB3\] {
    background-color: #E6CCB3 !important;
    print-color-adjust: exact !important;
  }

  /* Add page breaks between cards */
  .space-y-4 > div {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  /* Background for resistor container */
  .bg-gray-200, .dark .bg-gray-200 {
    background-color: #e5e7eb !important;
    print-color-adjust: exact !important;
  }

  /* Text colors */
  .text-gray-600 {
    color: #4b5563 !important;
  }

  /* Remove any shadows */
  .shadow {
    box-shadow: none !important;
  }

  /* Ensure borders print */
  .border {
    border: 1px solid #e5e7eb !important;
  }

  /* Dot spacing */
  .gap-\[1\.8cm\] {
    gap: 1.8cm !important;
  }
  
  .gap-\[1\.2cm\] {
    gap: 1.2cm !important;
  }

  .gap-\[0\.3cm\] {
    gap: 0.3cm !important;
  }
  
  /* Ensure dots print at correct size */
  .w-2 {
    width: 0.2cm !important;
    min-width: 0.2cm !important;
  }
  
  .h-2 {
    height: 0.2cm !important;
    min-height: 0.2cm !important;
  }
}

/* Preserve layout spacing */
.pr-8 {
  padding-right: 2rem !important;
}

/* Print text colors */
.print\:text-black {
  color: black !important;
}

.print\:text-gray-600 {
  color: #4b5563 !important;
}

.print\:text-red-600 {
  color: #dc2626 !important;
}

/* Print backgrounds */
.print\:bg-gray-200 {
  background-color: #e5e7eb !important;
  print-color-adjust: exact !important;
}

.print\:bg-\[\#E6CCB3\] {
  background-color: #E6CCB3 !important;
  print-color-adjust: exact !important;
}